export default {
  name: "dashboard-card",
  components: {},
  props: ["titleCard", "minHeight665"],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
